import React from 'react';
import { usePermissions } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { ReduxState } from 'ra-core';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  makeStyles,
} from '@material-ui/core';
import {
  User as UserIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  Layers as LayersIcon,
  TrendingUp as TrendingUpIcon,
  Sliders as SlidersIcon,
  Settings as SettingsIcon,
  Award as AwardIcon,
} from 'react-feather';

import { Section } from './interface';
import { NavSection } from './NavSection';
import { hasAccessToMaintenanceScreen } from '../../../Utils/MaintenancePersmissionsUtils'

export const MENU_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  desktopDrawer: {
    width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  reset: {
    margin: 0,
    fontSize: 13,
  },
}));

const Menu = () => {
  const classes = useStyles();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const { permissions } = usePermissions();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const employee = JSON.parse(localStorage.getItem('employee') || '{}');

  const sections: Section[] = [
    {
      subheader: 'Reportes',
      hasSectionPermission: true,
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          hasItemPermission: true,
        },
      ],
    },
    {
      subheader: 'Gestión',
      hasSectionPermission: true,
      items: [
        {
          title: 'Clientes Personales',
          icon: UsersIcon,
          href: '/people',
          resource: 'person',
          hasItemPermission: [
            permissions?.person.find.enabled,
            permissions?.person.create.enabled,
            permissions?.blacklist.find.enabled,
          ].includes(true),
          subItems: [
            {
              title: 'Listado de Clientes',
              href: '/people',
              resource: 'person',
              hasItemPermission: [permissions?.person.find.enabled].includes(
                true
              ),
            },
            {
              title: 'Crear Cliente',
              href: '/people/create',
              resource: 'person',
              hasItemPermission: [permissions?.person.create.enabled].includes(
                true
              ),
            },
            {
              title: 'Blacklist',
              href: '/blacklists?segment=person',
              resource: 'blacklist',
              hasItemPermission: [permissions?.blacklist.find.enabled].includes(
                true
              ),
            },
          ],
        },
        {
          title: 'Clientes Morales',
          icon: BriefcaseIcon,
          href: '/legal-people',
          resource: 'legal-people',
          hasItemPermission: [
            permissions?.['legal-people'].find.enabled,
            permissions?.['legal-people'].create.enabled,
            permissions?.blacklist.find.enabled,
          ].includes(true),
          subItems: [
            {
              title: 'Listado de Clientes',
              href: '/legal-people',
              resource: 'legal-people',
              hasItemPermission: [
                permissions?.['legal-people'].find.enabled,
              ].includes(true),
            },
            {
              title: 'Crear Cliente',
              href: '/legal-people/create',
              resource: 'legal-people',
              hasItemPermission: [
                permissions?.['legal-people'].create.enabled,
              ].includes(true),
            },
            {
              title: 'Blacklist',
              href: '/blacklists?segment=legalPerson',
              resource: 'blacklist',
              hasItemPermission: [permissions?.blacklist.find.enabled].includes(
                true
              ),
            },
          ],
        },
        {
          title: 'Solicitudes',
          icon: TrendingUpIcon,
          href: '/loan-applications',
          resource: 'loan-application',
          hasItemPermission: [
            permissions?.['loan-application'].find.enabled,
            permissions?.['loan-application'].create.enabled,
          ].includes(true),
          subItems: [
            {
              title: 'Listado de Solicitudes',
              href: '/loan-applications',
              resource: 'loan-application',
              hasItemPermission: [
                permissions?.['loan-application'].find.enabled,
              ].includes(true),
            },
            {
              title: 'Crear Solicitud',
              href: '/loan-applications/create',
              resource: 'loan-application',
              hasItemPermission: [
                permissions?.['loan-application'].create.enabled,
              ].includes(true),
            },
          ],
        },
        {
          title: 'Franquicias',
          icon: LayersIcon,
          href: '/franchises',
          resource: 'franchise',
          hasItemPermission: [
            permissions?.franchise.find.enabled,
            permissions?.franchise.create.enabled,
            permissions?.blacklist.find.enabled,
          ].includes(true),
          subItems: [
            {
              title: 'Listado de Franquicias',
              href: '/franchises',
              resource: 'franchise',
              hasItemPermission: [permissions?.franchise.find.enabled].includes(
                true
              ),
            },
            {
              title: 'Crear Franquicia',
              href: '/franchises/create',
              resource: 'franchise',
              hasItemPermission: [
                permissions?.franchise.create.enabled,
              ].includes(true),
            },
            {
              title: 'Blacklist',
              href: '/blacklists?segment=franchise',
              resource: 'blacklist',
              hasItemPermission: [permissions?.blacklist.find.enabled].includes(
                true
              ),
            },
          ],
        },
        {
          title: 'Garantías',
          icon: AwardIcon,
          href: '/guarantees/panel',
          resource: 'guarantee',
          hasItemPermission: [permissions?.guarantee.panel.enabled].includes(
            true
          ),
          subItems: [
            {
              title: 'Lista de Garantías',
              href: '/guarantees/panel',
              resource: 'guarantee',
              hasItemPermission: [
                permissions?.guarantee.panel.enabled,
              ].includes(true),
            },
          ],
        },
        {
          title: 'Empleados',
          icon: UserIcon,
          href: '/employee',
          resource: 'employee',

          hasItemPermission: [
            permissions?.employee.find.enabled,
            permissions?.employee.create.enabled,
          ].includes(true),
          subItems: [
            {
              title: 'Listado de empleados',
              href: '/employees',
              resource: 'employee',
              hasItemPermission: [permissions?.employee.find.enabled].includes(
                true
              ),
            },
            {
              title: 'Crear empleado',
              href: '/employees/create',
              resource: 'employee.create',
              hasItemPermission: [
                permissions?.employee.create.enabled,
              ].includes(true),
            },
          ],
        },
        {
          title: 'Reglas de Estados',
          icon: SlidersIcon,
          href: '/state-rule',
          resource: 'state-rule',
          hasItemPermission: [
            permissions?.['state-rule'].find.enabled,
            permissions?.['state-rule'].create.enabled,
          ].includes(true),
          subItems: [
            {
              title: 'Listado de reglas',
              href: '/state-rules',
              resource: 'state-rule',
              hasItemPermission: [
                permissions?.['state-rule'].find.enabled,
              ].includes(true),
            },
            {
              title: 'Crear regla',
              href: '/state-rules/create',
              resource: 'state-rule',
              hasItemPermission: [
                permissions?.['state-rule'].create.enabled,
              ].includes(true),
            },
          ],
        },
        {
          title: 'Usuarios',
          icon: UserIcon,
          href: '/users',
          resource: 'user',
          hasItemPermission: [permissions?.user.find.enabled].includes(true),
          subItems: [
            {
              title: 'Listado de usuarios',
              href: '/users',
              resource: 'user',
              hasItemPermission: [permissions?.user.find.enabled].includes(
                true
              ),
            },
          ],
        },
      ],
    },
    { 
      subheader: 'Mantenimientos',
      hasSectionPermission: hasAccessToMaintenanceScreen(),
      items: [
        {
          title: 'Mantenimientos',
          href: '/maintenances',
          icon: SettingsIcon,
          hasItemPermission: true,
        },
      ],
    },
  ];

  const content = (
    <Box height='100%'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display='flex' justifyContent='center'>
            <RouterLink to='#' /*replace with /app/account*/>
              <Avatar alt='User' className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign='center'>
            <Link
              component={RouterLink}
              to='#' //replace with /app/account
              variant='h5'
              color='textPrimary'
              underline='none'
            >
              {`${user?.person?.firstName} ${user?.person?.fatherLastName}`}
              <p className={classes.reset}>{`Rol: ${user.role.name}`}</p>
              <p className={classes.reset}>
                {employee ? `Código: ${employee.code}` : null}
              </p>
            </Link>
          </Box>
        </Box>
        {sections.map((section) =>
          section.hasSectionPermission ? (
            <>
              <Divider />
              <Box p={2}>
                <NavSection key={section.subheader} {...section} />
              </Box>
            </>
          ) : null
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.desktopDrawer }}
      open={open}
      variant='persistent'
    >
      {content}
    </Drawer>
  );
};

export default Menu;
