import React, { useEffect, useState } from 'react';
import {
  DateInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  maxLength,
  useTranslate,
  BooleanInput,
  regex,
  useGetOne,
  useGetList,
  useLoading,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Contacts from '../../Components/Contacts';
import Addresses from '../../Components/Addresses';
import Representatives from '../../Components/Representatives';
import FormTitle from '../../Components/FormTitle';
import InformationBank from '../../Components/InformationBank';
import FormatNumberInput from '../../Components/FormatNumberInput';
import OtherDocuments from '../../Components/OtherDocuments';
import { MORAL_PERSON_CONF } from './MoralPersonConf';
import { useForm } from 'react-final-form';
import useSelectDataProvider from '../../hooks/useSelectDataProvider';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
    },
  })
);

const MoralPersonFormElement = ({ props, formProps }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [showDictum, setShowDictum] = useState(props?.record?.blacklist);
  const loading = useLoading();

  const form = useForm();

  const record = (props as any).record || {};

  const documentTypeFilter = {
    documentSegment: 2,
    segment: 'info',
    active: true,
  };
  const documentTypeList = useSelectDataProvider('document-types', {
    defaultSelected: true,
    ...documentTypeFilter,
  });
  const documentTypeDefault =
    (record?.documentType?.id
      ? record?.documentType
      : (documentTypeList[0] as any)) || {};
  const [documentTypeSelected, setDocumentTypeSelected] = useState(0);
  const { data: documentTypeData } = useGetOne(
    'document-types',
    documentTypeSelected
  );

  const onChangeDocumentType = (ev) => {
    const value = ev.target?.value;
    setDocumentTypeSelected(value);
    form.change('documentNumber', null);
  };

  const getDocumentTypeValidator = () => {
    if (documentTypeData) {
      return documentTypeData?.validatorRegex
        ? regex(
            new RegExp(documentTypeData?.validatorRegex),
            'resources.validators.validation.invalid-format'
          )
        : maxLength(40);
    }
    if (documentTypeDefault) {
      return documentTypeDefault?.validatorRegex
        ? regex(
            new RegExp(documentTypeDefault?.validatorRegex),
            'resources.validators.validation.invalid-format'
          )
        : maxLength(40);
    }

    return maxLength(40);
  };

  return (
    <>
      <Grid container spacing={2} classes={{ root: classes.root }}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            General
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <ReferenceInput
                source='documentType.id'
                resource='people'
                reference='document-types'
                fullWidth
                variant='outlined'
                validate={[required()]}
                defaultValue={(documentTypeList[0] as any)?.id}
                disabled
                onChange={onChangeDocumentType}
                inputProps
                filter={documentTypeFilter}
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source='documentNumber'
                resource='legal-people'
                validate={[required(), getDocumentTypeValidator()]}
                fullWidth
                variant='outlined'
                format={(value: string) => value && value.toLocaleUpperCase()}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source='businessName'
                resource='legal-people'
                validate={[required(), maxLength(100)]}
                fullWidth
                variant='outlined'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source='tradeName'
                resource='legal-people'
                fullWidth
                validate={[maxLength(100)]}
                variant='outlined'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <DateInput
                source='constitutionDate'
                resource='legal-people'
                fullWidth
                variant='outlined'
                disabled={loading}
                inputProps={{
                  max: new Date().toISOString().split('T')[0],
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DateInput
                source='registerIssueDate'
                resource='legal-people'
                fullWidth
                variant='outlined'
                disabled={loading}
                inputProps={{
                  max: new Date().toISOString().split('T')[0],
                }}
              />
            </Grid>
            {MORAL_PERSON_CONF.fields.constitutionCountry && (
              <Grid item xs={4}>
                <ReferenceInput
                  label={translate(
                    'resources.legal-people.fields.constitutionCountry'
                  )}
                  source='constitutionCountry.id'
                  validate={[required()]}
                  reference='countries'
                  fullWidth
                  variant='outlined'
                  disabled={loading}
                >
                  <SelectInput optionText='name' />
                </ReferenceInput>
              </Grid>
            )}
            <Grid item xs={4}>
              <FormatNumberInput
                source='statementIncome'
                resource='legal-people'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <FormatNumberInput
                source='financialInformationIncome'
                resource='legal-people'
                disabled={loading}
              />
            </Grid>
            <Grid container alignItems='center' xs={3}>
              <Grid item xs={5} style={{ marginLeft: '10px' }}>
                <BooleanInput
                  style={{ marginLeft: '5%' }}
                  source='isFranchiseManager'
                  label='¿Es encargado de franquicia?'
                  resource='people'
                  defaultValue={false}
                />
              </Grid>
            </Grid>
            {formProps.record?.id && (
              <Grid item xs={4}>
                <BooleanInput
                  source='blacklist'
                  resource='legal-people'
                  defaultValue={false}
                  onChange={(val) => setShowDictum(val)}
                />
              </Grid>
            )}
            {showDictum ? (
              <Grid item xs={4}>
                <ReferenceInput
                  fullWidth
                  source='blacklistDictum'
                  variant='outlined'
                  disabled={loading}
                  reference='blacklist-dictums'
                  resource='blacklists'
                  validate={required()}
                >
                  <SelectInput optionText='code' />
                </ReferenceInput>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {MORAL_PERSON_CONF.sections.other_documents && (
          <Grid item xs={12}>
            <FormTitle title='Otros Documentos' />
            <OtherDocuments record={formProps.record} {...props} />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTitle
            title={translate('resources.contacts.fields.name')}
            className={classes.width100}
          />
          <Contacts record={formProps.record} />
        </Grid>
        <Grid item xs={12}>
          <FormTitle
            title={translate('resources.addresses.fields.name')}
            className={classes.width100}
          />
          <Addresses record={formProps.record} />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title='Representantes' className={classes.width100} />
          <Representatives {...formProps} />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={translate('Información Bancaria')} />
          <InformationBank record={formProps.record} />
        </Grid>
      </Grid>
      <Toolbar>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <SaveButton
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            redirect='list'
          />
        </Box>
      </Toolbar>
    </>
  );
};

export const MoralPersonForm = (props) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <MoralPersonFormElement props={props} formProps={formProps} />
        </form>
      )}
    />
  );
};

export default MoralPersonForm;
