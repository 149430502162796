import React, { useEffect, useRef, useState } from 'react';
import {
  DateInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  useTranslate,
  SelectInput,
  maxLength,
  TextInput,
  BooleanInput,
  useGetOne,
  regex,
  useLoading,
  useNotify,
  useCreate,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { PersonTypeAlias, sex } from '../../Utils/enums';
import Contacts from '../../Components/Contacts';
import Addresses from '../../Components/Addresses';
import FormTitle from '../../Components/FormTitle';
import Representatives from '../../Components/Representatives';
import InformationBank from '../../Components/InformationBank';
import FormatNumberInput from '../../Components/FormatNumberInput';
import OtherDocuments from '../../Components/OtherDocuments';
import { PERSON_CONF } from './PersonConf';
import useSelectDataProvider from '../../hooks/useSelectDataProvider';
import { useForm } from 'react-final-form';
import { conditionForLinkedFranchise } from '../../Utils/PhysicalPerson';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
  })
);

export const PersonFormElement = ({ props, formProps }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isClient, setIsClient] = useState(true);
  const [showDictum, setShowDictum] = useState(props?.record?.blacklist);
  const loading = useLoading();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const today = new Date().toISOString().split('T')[0].split('-');
  const refSelectProfession = useRef<any>(null);
  const [profession, setProfession] = useState('');
  const [isEmployee, setIsEmployee] = useState(
    conditionForLinkedFranchise(formProps?.record?.isFranchiseManager)
  );
  const [isFranchiseManager, setIsFranchiseManager] = useState(
    conditionForLinkedFranchise(formProps?.record?.isEmployee)
  );

  const notify = useNotify();

  const form = useForm();

  const record = (props as any).record || {};

  const documentTypeFilter = {
    documentSegment: 1,
    segment: 'info',
    active: true,
  };
  const documentTypeList = useSelectDataProvider('document-types', {
    defaultSelected: true,
    ...documentTypeFilter,
  });
  const documentTypeDefault =
    (record?.documentType?.id
      ? record?.documentType
      : (documentTypeList[0] as any)) || {};

  const [documentTypeSelected, setDocumentTypeSelected] = useState(0);
  const { data: documentTypeData } = useGetOne(
    'document-types',
    documentTypeSelected
  );

  const onChangeDocumentType = (ev) => {
    const value = ev.target?.value;
    setDocumentTypeSelected(value);
    form.change('documentNumber', null);
  };

  const getDocumentTypeValidator = () => {
    if (documentTypeData) {
      return documentTypeData?.validatorRegex
        ? regex(
            new RegExp(documentTypeData?.validatorRegex),
            'resources.validators.validation.invalid-format'
          )
        : maxLength(40);
    }
    if (documentTypeDefault) {
      return documentTypeDefault?.validatorRegex
        ? regex(
            new RegExp(documentTypeDefault?.validatorRegex),
            'resources.validators.validation.invalid-format'
          )
        : maxLength(40);
    }
    return maxLength(40);
  };

  useEffect(() => {
    if (refSelectProfession.current) {
      const selectedProfession =
        refSelectProfession.current?.node?.parentNode?.outerText;
      if (typeof selectedProfession === 'string') {
        setProfession(selectedProfession.trim());
      }
    }
  }, [refSelectProfession.current?.node?.parentNode?.outerText]);

  return (
    <>
      <Grid container spacing={2} classes={{ root: classes.root }}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            General
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput
                source='firstName'
                resource='people'
                validate={[required(), maxLength(40)]}
                fullWidth
                variant='outlined'
                autoComplete='off'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                source='secondName'
                resource='people'
                validate={[maxLength(40)]}
                fullWidth
                variant='outlined'
                autoComplete='off'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                source='fatherLastName'
                resource='people'
                validate={[required(), maxLength(40)]}
                fullWidth
                variant='outlined'
                autoComplete='off'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                source='motherLastName'
                resource='people'
                validate={[maxLength(40)]}
                fullWidth
                variant='outlined'
                autoComplete='off'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source='documentType.id'
                resource='people'
                reference='document-types'
                fullWidth
                variant='outlined'
                validate={[required()]}
                defaultValue={(documentTypeList[0] as any)?.id}
                disabled
                onChange={onChangeDocumentType}
                inputProps
                filter={documentTypeFilter}
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
              <TextInput
                source='documentNumber'
                resource='people'
                validate={[required(), getDocumentTypeValidator()]}
                fullWidth
                variant='outlined'
                format={(value: string) => value && value.toLocaleUpperCase()}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <DateInput
                source='birthday'
                resource='people'
                inputProps={{
                  max: isClient
                    ? [Number(today[0]) - 21, today[1], today[2]].join('-')
                    : today.join('-'),
                }}
                validate={[required()]}
                fullWidth
                variant='outlined'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source='nationality.id'
                resource='people'
                validate={[required()]}
                reference='countries'
                fullWidth
                variant='outlined'
                perPage={100}
                sort={{
                  field: 'id',
                  order: 'ASC',
                }}
                disabled={loading}
              >
                <SelectInput optionText='nationality' />
              </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source='maritalStatus.id'
                resource='people'
                reference='marital-statuses'
                fullWidth
                variant='outlined'
                disabled={loading}
              >
                <SelectInput />
              </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source='sex'
                resource='people'
                choices={sex}
                fullWidth
                variant='outlined'
                disabled={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source='academicLevel.id'
                resource='people'
                reference='academic-levels'
                fullWidth
                variant='outlined'
                disabled={loading}
                sort={{
                  field: 'id',
                  order: 'ASC',
                }}
              >
                <SelectInput />
              </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source='profession.id'
                resource='people'
                reference='professions'
                fullWidth
                variant='outlined'
                disabled={loading}
              >
                <SelectInput inputRef={refSelectProfession} />
              </ReferenceInput>
            </Grid>
            {profession &&
            (profession.toLowerCase() === 'otros' ||
              profession.toLowerCase() === 'otro') ? (
              <Grid item xs={3}>
                <TextInput
                  source='professionName'
                  label='Nombre Profesión'
                  resource='people'
                  validate={[required(), maxLength(40)]}
                  fullWidth
                  variant='outlined'
                  autoComplete='off'
                  disabled={loading}
                />
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <ReferenceInput
                source='occupation.id'
                resource='people'
                reference='occupations'
                fullWidth
                variant='outlined'
                disabled={loading}
              >
                <SelectInput />
              </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source='economicActivity.id'
                resource='people'
                reference='economic-activities'
                fullWidth
                variant='outlined'
                disabled={loading}
              >
                <SelectInput />
              </ReferenceInput>
            </Grid>
            <Grid container alignItems='center' xs={3}>
              {!isFranchiseManager ? (
                <Grid xs={6}>
                  <BooleanInput
                    style={{ marginLeft: '5%' }}
                    source='isEmployee'
                    resource='people'
                    onChange={(e) => {
                      setIsEmployee(e);
                    }}
                    defaultValue={false}
                  />
                </Grid>
              ) : null}
              <Grid item xs={5} style={{ marginLeft: '10px' }}>
                <BooleanInput
                  style={{ marginLeft: '5%' }}
                  source='isClient'
                  onChange={(value) => setIsClient(value)}
                  resource='people'
                  defaultValue={true}
                />
              </Grid>
              {!isEmployee ? (
                <Grid item xs={5} style={{ marginLeft: '10px' }}>
                  <BooleanInput
                    style={{ marginLeft: '5%' }}
                    source='isFranchiseManager'
                    label='¿Es encargado de franquicia?'
                    resource='people'
                    onChange={(e) => {
                      setIsFranchiseManager(e);
                    }}
                    defaultValue={false}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <FormatNumberInput
                source='billingIncome'
                resource='people'
                disabled={loading}
              />
            </Grid>
            <Grid
              item
              container
              spacing={2}
              style={{
                marginTop: '1.5rem',
              }}
            >
              <Grid item xs={4}>
                <FormatNumberInput
                  source='statementIncome'
                  resource='people'
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={4}>
                <FormatNumberInput
                  source='financialInformationIncome'
                  resource='people'
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={4}>
                <FormatNumberInput
                  label='Ingresos por nómina'
                  source='payrollIncome'
                  resource='people'
                  disabled={loading}
                />
              </Grid>
            </Grid>
            {formProps.record?.id && (
              <Grid item xs={3}>
                <BooleanInput
                  source='blacklist'
                  resource='people'
                  defaultValue={false}
                  onChange={(val) => setShowDictum(val)}
                  disabled={loading}
                />
              </Grid>
            )}
            {showDictum ? (
              <Grid item xs={3}>
                <ReferenceInput
                  fullWidth
                  source='blacklistDictum'
                  variant='outlined'
                  disabled={loading}
                  reference='blacklist-dictums'
                  resource='blacklists'
                  validate={required()}
                >
                  <SelectInput optionText='code' />
                </ReferenceInput>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {PERSON_CONF.sections.other_documents && (
          <Grid item xs={12}>
            <FormTitle title='Otros Documentos' />
            <OtherDocuments record={formProps.record} {...props} />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTitle title='Contactos' />
          <Contacts
            record={formProps.record}
            isValidate={true}
            typePerson={PersonTypeAlias.Physical}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title='Direcciones' />
          <Addresses
            record={formProps.record}
            isValidate={true}
            typePerson={PersonTypeAlias.Physical}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={translate('Información Bancaria')} />
          <InformationBank record={formProps.record} />
        </Grid>
      </Grid>
      <Toolbar>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <SaveButton
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            redirect='list'
          />
        </Box>
      </Toolbar>
    </>
  );
};

export const PersonForm = (props) => {
  const notify = useNotify();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <PersonFormElement props={props} formProps={formProps} />
        </form>
      )}
    />
  );
};

export default PersonForm;
